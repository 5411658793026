/*
 * 업무구분 : 고객홈
 * 화 면 명 : MSPCM286D
 * 화면설명 : 터치이력 - FC터치
 * 작 성 일 : 2023.02.16
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp">
    <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-cm-wrap" :class="{'pb80' : fn_CmptdFCTuchTot > 0}">
      <div class="mt20 mb20 pr24 fex-rw justify-end">
        <mo-button @click="fn_getCallHis" class="ns-btn-round blackW sm">전화이력조회</mo-button>
      </div>
      <ur-box-container v-if="fn_CmptdFCTuchTot > 0" alignV="start" componentid="ur_box_container_001" direction="column" class="status-list type-expand list-pa2024 bd-b-Ty1 mb70">
        <mo-list-item v-for="(listItem, idx) in fn_CmptdTuchListSVO" :key="idx"> 
          <!-- msp-expand 에 맨 첫번째 값만 펼침 일 겨우 첫번째에 expanded 넣어주세요! -->
          <msp-expand btn-area-first title-first expand-only-btn :expanded="idx < 1" class="mo-list-expand" btn-area-class="fexTy3 align-item-start">
            <template #title>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="name txtSkip fs19rem">{{listItem.tuchYear}}</span>
                </div>
              </div>
            </template>
            <template #btn>
                <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="full-list mt20">
                <div class="list-item__contents con-area mt10">
                  <!-- <div class="mt10 fex-rw justify-end">
                    <mo-button @click="fn_getCallHis" class="ns-btn-round blackW sm">전화이력조회</mo-button>
                  </div> -->
                  <div v-for="(item,idx) in listItem.lv_FCTuchList" :key="idx" class="item-box mt10" >
                    <ur-box-container alignV="start" componentid="" direction="column" class="round-box pa1216" 
                    :class="{ 'bgcolor-1' : item.classType === 0, 'bgcolor-blue' : item.classType === 1 }">
                      <div class="full fexTy5 fs16rem">
                        <b class="min60">{{item.tuchDate}}</b>
                        <span>{{item.actTypScCdNm}}</span><em>|</em><span>{{item.detaActScCdNm}}</span>
                      </div>
                      <div class="full mt4 fs16rem">
                        <span class="ml60">{{item.tuchCntnt}}</span>
                      </div>
                      <div class="full fexTy5 fs16rem" v-if="actTypScCdNm = '대면'">
                        <span class="ml60" v-html="item.memo"></span>
                      </div>
                    </ur-box-container>
                  </div>
                </div>
              </div>
            </template>
          </msp-expand>
        </mo-list-item>
      </ur-box-container>

      <!-- NoData 영역: start  -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list no-data">
        <!-- <div class="mt10 fex-rw justify-end">
          <mo-button @click="fn_getCallHis" class="ns-btn-round blackW sm">전화이력조회</mo-button>
        </div> -->
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- NoData 영역: end  -->

    </ur-box-container>

  </ur-box-container>
</template>

<script>
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  // 현재 화면명
  name: 'MSPCM286D', 
  // 현재 화면 ID
  screenId: 'MSPCM286D', 
  /***********************************************************************************
   * 화면 UI Property 정의
  ***********************************************************************************/
  props:{
    pCustInfo: {
      type: Object,
      default: {}
    },
    rsltBasSVO: {
      type: Object,
      default: {}
    }
  },
  /***********************************************************************************
   * 화면 전역 변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      title: '터치이력-FC터치',
      lv_UserInfo: {}, // 사용자 정보
      lv_FCTuchListSVO: [],
    }
  },
  /***********************************************************************************
   * watch 정의 영역                                                                 *
  ***********************************************************************************/
  watch: {
    'pCustInfo.lv_FCTuchListSVO'() {
      console.log('왓치>>', this.pCustInfo)

      // 2025-02-18 일정데이터인 경우, 활동명 "대면"으로 고정
      if (!_.isEmpty(this.pCustInfo)) {
        let data = this.pCustInfo.lv_FCTuchListSVO
        for(let i=0;i<data.length;i++) {
          if(data[i].detaActScCdNm === "FC방문입력") {

            let setData = ''

            data[i].actTypScCdNm = '대면'
          
            if(!this.$bizUtil.isEmpty(data[i].tuchCntnt)) {
              let pData = data[i].tuchCntnt.split("memo:")
              if(!this.$bizUtil.isEmpty(pData[0])) {
                let hour24  = parseInt(pData[0].slice(0,2))
                let minutes = parseInt(pData[0].slice(2,4))

                let hour12  = hour24 % 12
                if(hour12.toString().length === 1) {
                  hour12 = "0" + hour12
                }
                if(minutes.toString().length === 1) {
                  minutes = "0" + minutes
                }
                if(pData[0].substr(0,2) > 11) {
                  setData = "오후 " + hour12 + ":" + minutes
                } else {
                  setData = "오전 " + hour12 + ":" + minutes
                  }
              } else {
                setData = "종일"
              }
              data[i].tuchCntnt = setData
              if(!this.$bizUtil.isEmpty(pData[1])) {
                setData = pData[1]
                data[i].memo = setData
              }
            }
          }
        }
        this.lv_FCTuchListSVO = data
      }
    },
    'rsltBasSVO'() {
      console.log('rsltBasSVO watch>>', this.rsltBasSVO)
    }
  },
  computed: {
    fn_CmptdFCTuchTot() {
      return this.lv_FCTuchListSVO ? this.lv_FCTuchListSVO.length : 0
    },
    // FC 터치이력 List 셋팅
    fn_CmptdTuchListSVO() {
      const lv_Vm = this

      let rtnList = []
      if (_.isEmpty(this.lv_FCTuchListSVO)) return rtnList

      let flag = ''
      let i = 0
      this.lv_FCTuchListSVO.forEach(item => {
        let dataYear = item.tuchYmd.substr(0,4)
        let sameYearList = rtnList.filter(item => item.tuchYear === dataYear)

        if (sameYearList.length < 1) {
          let dataList = lv_Vm.lv_FCTuchListSVO.filter(item => {
              // 터치 발생일 셋팅
              item.tuchMonth = item.tuchYmd.substr(4,2)
              item.tuchDate = item.tuchYmd ? item.tuchMonth + '-' + item.tuchYmd.substr(6,2) : ''

              // classType (0: blue, 1: gary)
              if (flag === '') {
                  flag = item.tuchMonth
              } else if (flag !== item.tuchMonth) {
                  flag = item.tuchMonth
                  i++;
              }
              
              if (item.tuchMonth === flag) {
                  item.groupMonth = i
                  item.classType = i%2
              }

              return item.tuchYmd.indexOf(dataYear) > -1
            });

          rtnList.push({tuchYear: dataYear, lv_FCTuchList: dataList})
        }
      });

      return rtnList
    }
    
  },

  methods: {
    fn_getCallHis() {
      console.log('버튼클릭!!!') 
      this.$router.push({ name: 'MSPCM290D', params:{userInfo: this.lv_UserInfo, rsltBasSVO: this.rsltBasSVO}})
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created() {
    console.log('■■■■■■■ MSPCM286D ■■■■■■■')
    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
    console.log('ㄴㄴㄴㄴ', this.lv_UserInfo)
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM286D')
  },
};
</script>
